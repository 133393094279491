import { types } from 'actions/organisation';
import {
  searchSuperOrganisationResearchers,
  purchasingSearch,
  searchSuperOrganisationWorkItems,
  searchSuperOrganisationClinicalTrials,
} from 'services/api';
import { buildQuery, noValue } from 'utils/search';
import { searchId as id } from 'components/common';
import { buildQuery as PurchasingBuildQuery } from 'utils/purchasing';
import { handleSearchError } from '../notifications';
import { getHasValidQuery, getHasValidItemSearchQuery } from './selectors';
import {
  ORGANISATIONCLINICALTRIALSVIEW,
  ORGANISATIONPEOPLEVIEW,
  ORGANISATIONPURCHASINGVIEW,
  ORGANISATIONWORKITEMVIEW,
} from 'components/Organisation/helpers';

const resetSkipKeys = ['term', 'category', 'date'];

const defaultState = {
  organisationResearchers: {},
  organisationExhibitors: {},
  organisationClinicalTrials: {},
  totals: {},
  workItemTotals: {},
  tradeshowTotals: {},
  clinicalTrialTotals: {},
  clinicalTrialPageNumbers: {},
  clinicalTrialPageSize: {},
  clinicalTrialSort: {},
  clinicalTrialFilters: {},
  pageNumbers: {},
  pageSizes: {},
  loading: {},
  researcherSearchError: {},
  workItemSearchError: {},
  clinicalTrialSearchError: {},
  filters: {},
  changedFilters: {},
  sort: {},
  purchasingLastSuccessfulSearch: {},
  purchasingSearchRequest: {},
  purchasingFilters: {
    term: '',
  },
  purchasingPageSize: 100,
  purchasingPageNumber: 0,
  purchasingLoading: false,
  showNotice: true,
  showClinicalTrialNotice: true,
  workItemPageNumbers: {},
  workItemPageSize: {},
  workItemSort: {},
  workItemSearchRequest: {},
  workItemLoading: {},
  workItems: {},
  workItemFilters: {},
  workItemChangedFilters: false,
  view: 'workItems',
  loadingExhibitorData: {},
  loadingClinicalTrialData: {},
  blockedForWorkItemExport: {},
  blockedForResearchersExport: {},
  blockedForClinicalTrialExport: {},
};

const REDUCER = {
  [types.SET_RESEARCHERS_SEARCH_REQUEST]: (state, action) => {
    const { organisationId, searchRequest } = action.payload;

    if (action.payload.searchRequest?.error) {
      handleSearchError(action.payload.searchRequest.error);
    }
    if (searchRequest.res) {
      return {
        ...state,
        organisationResearchers: {
          ...state.organisationResearchers,
          [organisationId]: { ...searchRequest.res, retrievalDate: new Date() },
        },
        loading: { ...state.loading, [organisationId]: false },
        blockedForResearchersExport: {
          ...state.blockedForResearcherExport,
          [organisationId]: false,
        },
        totals: {
          ...state.totals,
          [organisationId]: {
            people: searchRequest.res.distinctSuperResearchersCount,
            emailCount: searchRequest.res.emailCount,
            countries: searchRequest.res.countryCount,
            activities:
              searchRequest.res.totalFunding +
              searchRequest.res.totalPosters +
              searchRequest.res.totalPublications +
              searchRequest.res.totalClinicalTrials,
          },
        },
        researcherSearchError: {
          ...state.researcherSearchError,
          [organisationId]: action.payload.searchRequest?.error,
        },
      };
    }
    if (searchRequest.loading) {
      return {
        ...state,
        loading: { ...state.loading, [organisationId]: searchRequest.loading },
        researcherSearchError: {
          ...state.researcherSearchError,
          [organisationId]: action.payload.searchRequest?.error,
        },
      };
    }

    return {
      ...state,
      loading: { ...state.loading, [organisationId]: false },
      researcherSearchError: {
        ...state.researcherSearchError,
        [organisationId]: action.payload.searchRequest?.error,
      },
      organisationResearchers: {},
      totals: {},
      blockedForResearchersExport: {
        ...state.blockedForResearcherExport,
        [organisationId]: false,
      },
    };
  },
  [types.RESTORE_STATE]: (state, action) => {
    if (!action.payload) {
      return {
        ...defaultState,
        ...state,
      };
    }

    let restoredState = JSON.parse(JSON.stringify(action.payload));
    restoredState = {
      ...defaultState,
      ...restoredState,
    };
    return restoredState;
  },

  [types.SET_SORT]: (state, action) => {
    const { organisationId, value } = action.payload;
    return {
      ...state,
      sort: { ...state.sort, [organisationId]: value },
    };
  },

  [types.PERFORM_SEARCH]: (state, action) => {
    const { organisationId, request } = action.payload;
    if (!getHasValidQuery(state, organisationId)) {
      return {
        ...state,
        changedFilters: {
          ...state.changedFilters,
          [organisationId]: true,
        },
      };
    }

    const initialPageSize = 25;
    const initialPageNumber = 0;
    const initialOrder = 'LastActivityDate';
    const initialOrderBy = 'desc';
    const [query, , terms] = buildQuery(
      state.filters[organisationId],
      null,
      null,
      true
    );

    const pageSize = state.pageSizes[organisationId]
      ? state.pageSizes[organisationId]
      : initialPageSize;

    const pageNumber = state.changedFilters[organisationId]
      ? initialPageNumber
      : state.pageNumbers[organisationId]
      ? state.pageNumbers[organisationId]
      : initialPageNumber;

    const order = state.sort[organisationId]
      ? state.sort[organisationId].field
      : initialOrder;

    const orderBy = state.sort[organisationId]
      ? state.sort[organisationId].direction
      : initialOrderBy;

    const cancelResearchersSearch = request({
      url: searchSuperOrganisationResearchers(organisationId),
      body: {
        id,
        query: `(${query})`,
        count: pageSize,
        from: pageSize * pageNumber,
        order,
        orderBy,
        filters: { ...terms },
      },
    });

    let changedFiltersState = { ...state.changedFilters };
    delete changedFiltersState[organisationId];
    return {
      ...state,
      pageNumbers: { ...state.pageNumbers, [organisationId]: pageNumber },
      pageSizes: { ...state.pageSizes, [organisationId]: pageSize },
      sort: {
        ...state.sort,
        [organisationId]: { field: order, direction: orderBy },
      },
      changedFilters: changedFiltersState,
      cancelResearchersSearch,
    };
  },

  [types.SET_PAGE_NUMBER]: (state, action) => {
    const { organisationId, pageNumber } = action.payload;
    return {
      ...state,
      pageNumbers: { ...state.pageNumbers, [organisationId]: pageNumber },
    };
  },
  [types.SET_PAGE_SIZE]: (state, action) => {
    const { organisationId, pageSize } = action.payload;
    return {
      ...state,
      pageSizes: { ...state.pageSizes, [organisationId]: pageSize },
      pageNumbers: { ...state.pageNumber, [organisationId]: 0 },
    };
  },
  [types.SET_TOTALS]: (state, action) => {
    const { organisationId, totals } = action.payload;
    return {
      ...state,
      totals: { ...state.totals, [organisationId]: totals },
    };
  },

  [types.RESET_FILTERS]: (state, action) => {
    const { organisationId } = action.payload;
    let newState = { ...state.filters[organisationId] };
    if (noValue(newState)) {
      newState = {};
    } else {
      Object.keys(newState).forEach(key => {
        if (resetSkipKeys.findIndex(k => k === key) < 0) delete newState[key];
      });
    }
    return {
      ...state,
      filters: { ...state.filters, [organisationId]: newState },
      changedFilters: { ...state.changedFilters, [organisationId]: true },
      blockedForResearchersExport: {
        ...state.blockedForResearcherExport,
        [organisationId]: true,
      },
    };
  },

  [types.CLEAR_FILTERS]: (state, action) => {
    const { organisationId } = action.payload;
    let newState = { ...state.filters[organisationId] };
    if (noValue(newState)) {
      newState = {};
    } else {
      Object.keys(newState).forEach(key => {
        delete newState[key];
      });
    }
    return {
      ...state,
      filters: { ...state.filters, [organisationId]: newState },
      changedFilters: { ...state.changedFilters, [organisationId]: true },
      blockedForResearchersExport: {
        ...state.blockedForResearcherExport,
        [organisationId]: true,
      },
    };
  },

  [types.SET_EXHIBITOR_FILTER]: (state, action) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [action.payload.id]: action.payload.value,
      },
    };
  },

  [types.SET_TRADESHOWS_SEARCH_REQUEST]: (state, action) => {
    const { organisationId, searchRequest } = action.payload;
    if (searchRequest.res) {
      return {
        ...state,
        tradeshowTotals: {
          ...state.tradeshowTotals,
          [organisationId]: {
            people: searchRequest.res.distinctSuperResearchersCount,
            countries: searchRequest.res.countryCount,
            emailCount: searchRequest.res.emailCount,
            activities:
              searchRequest.res.totalFunding +
              searchRequest.res.totalPosters +
              searchRequest.res.totalPublications +
              searchRequest.res.totalClinicalTrials,
          },
        },
        organisationExhibitors: {
          ...state.organisationExhibitors,
          [organisationId]: searchRequest.res,
        },
        loadingExhibitorData: {
          ...state.loadingExhibitorData,
          [organisationId]: false,
        },
      };
    }
    return {
      ...state,
      loadingExhibitorData: {
        ...state.loadingExhibitorData,
        [organisationId]: searchRequest.loading ?? false,
      },
    };
  },

  [types.PERFORM_TRADESHOWS_SEARCH]: (state, action) => {
    const { organisationId, request } = action.payload;

    const initialOrder = 'LastActivityDate';
    const initialOrderBy = 'desc';

    const order = state.sort[organisationId]
      ? state.sort[organisationId].field
      : initialOrder;

    const orderBy = state.sort[organisationId]
      ? state.sort[organisationId].direction
      : initialOrderBy;

    request({
      url: searchSuperOrganisationResearchers(organisationId),
      body: {
        id,
        query: `(*)`,
        count: 1,
        order,
        orderBy,
        isTradeshowsSearch: true,
      },
    });

    return {
      ...state,
    };
  },

  [types.SET_CLINICALTRIALS_SEARCH_REQUEST]: (state, action) => {
    const { organisationId, searchRequest } = action.payload;
    if (searchRequest.res) {
      return {
        ...state,
        clinicalTrialTotals: {
          ...state.clinicalTrialTotals,
          [organisationId]: {
            people: searchRequest.res.distinctSuperResearchersCount,
            emailCount: searchRequest.res.emailCount,
            countries: searchRequest.res.countryCount,
            activities: searchRequest.res.distinctClinicalTrialsCount,
          },
        },
        blockedForClinicalTrialExport: {
          ...state.blockedForClinicalTrialExport,
          [organisationId]: false,
        },
        organisationClinicalTrials: {
          ...state.organisationClinicalTrials,
          [organisationId]: searchRequest.res,
        },
        loadingClinicalTrialData: {
          ...state.loadingClinicalTrialData,
          [organisationId]: false,
        },
        clinicalTrialSearchError: {
          ...state.clinicalTrialSearchError,
          [organisationId]: action.payload.searchRequest?.error,
        },
        clinicalTrialChangedFilters: {
          ...state.clinicalTrialChangedFilters,
          [organisationId]: false,
        },
      };
    }
    return {
      ...state,
      loadingClinicalTrialData: {
        ...state.loadingClinicalTrialData,
        [organisationId]: searchRequest.loading ?? false,
      },
      clinicalTrialSearchError: {
        ...state.clinicalTrialSearchError,
        [organisationId]: action.payload.searchRequest?.error,
      },
      clinicalTrials: {},
      clinicalTrialTotals: {},
      blockedForClinicalTrialExport: {
        ...state.blockedForClinicalTrialExport,
        [organisationId]: false,
      },
      clinicalTrialChangedFilters: {
        ...state.clinicalTrialChangedFilters,
        [organisationId]: false,
      },
    };
  },

  [types.PERFORM_CLINICALTRIALS_SEARCH]: (state, action) => {
    const { body, request } = action.payload;

    const initialPageNumber = 0;

    const pageNumber =
      state.clinicalTrialPageNumbers[body.organisationId] ?? initialPageNumber;

    const [query, highlightQuery, , nestedQueries] = buildQuery(
      state.clinicalTrialFilters[body.organisationId],
      null,
      null
    );

    const cancelClinicalTrialsSearch = request({
      url: searchSuperOrganisationClinicalTrials(body.organisationId),
      body: {
        id,
        query: `Category:ClinicalTrials AND ${query}`,
        nestedQueries: nestedQueries,
        count: state.clinicalTrialPageSize[body.organisationId] ?? 50,
        from:
          (state.clinicalTrialPageSize[body.organisationId] ?? 50) * pageNumber,
        orderBy:
          state.clinicalTrialSort[body.organisationId]?.field ||
          'FirstPostedDate',
        order:
          state.clinicalTrialSort[body.organisationId]?.direction || 'desc',
        collapseField: 'NctId.keyword',
        isClinicalTrialSearch: true,
        highlightQuery,
      },
    });

    return {
      ...state,
      cancelClinicalTrialsSearch,
    };
  },
  [types.SET_CLINICALTRIAL_SORT]: (state, action) => {
    const { organisationId, sort } = action.payload;
    return {
      ...state,
      clinicalTrialSort: { ...state.clinicalTrialSort, [organisationId]: sort },
    };
  },
  [types.SET_CLINICALTRIAL_PAGE_NUMBER]: (state, action) => {
    const { organisationId, pageNumber } = action.payload;
    return {
      ...state,
      clinicalTrialPageNumbers: {
        ...state.clinicalTrialPageNumbers,
        [organisationId]: pageNumber,
      },
    };
  },
  [types.SET_CLINICALTRIAL_PAGE_SIZE]: (state, action) => {
    const { organisationId, pageSize } = action.payload;
    return {
      ...state,
      clinicalTrialPageSize: {
        ...state.clinicalTrialPageSize,
        [organisationId]: pageSize,
      },
    };
  },
  [types.SET_CLINICALTRIAL_FILTER_GROUP]: (state, action) => {
    let newState = { ...state.clinicalTrialFilters };

    const { organisationId, id, value } = action.payload;
    if (noValue(value) && id !== 'term') {
      delete newState[organisationId][id];
    } else {
      newState = {
        ...newState,
        [organisationId]: {
          ...newState[organisationId],
          [id]: value,
        },
      };
    }
    if (Object.keys(newState[organisationId]).length === 0) {
      delete newState[organisationId];
    }

    return {
      ...state,
      clinicalTrialFilters: newState,
      blockedForClinicalTrialExport: {
        ...state.blockedForClinicalTrialExport,
        [organisationId]: true,
      },
      clinicalTrialChangedFilters: {
        ...state.clinicalTrialChangedFilters,
        [organisationId]: true,
      },
    };
  },
  [types.RESET_CLINICALTRIAL_FILTERS]: (state, action) => {
    const { organisationId } = action.payload;
    let newState = { ...state.clinicalTrialFilters[organisationId] };
    if (noValue(newState)) {
      newState = {};
    } else {
      Object.keys(newState).forEach(key => {
        if (resetSkipKeys.findIndex(k => k === key) < 0) delete newState[key];
      });
    }

    return {
      ...state,
      clinicalTrialFilters: {
        ...state.clinicalTrialFilters,
        [organisationId]: newState,
      },
      blockedForClinicalTrialExport: {
        ...state.blockedForClinicalTrialExport,
        [organisationId]: true,
      },
      clinicalTrialChangedFilters: {
        ...state.clinicalTrialChangedFilters,
        [organisationId]: true,
      },
    };
  },
  [types.CLEAR_CLINICALTRIAL_FILTERS]: (state, action) => {
    const { organisationId } = action.payload;
    let newState = { ...state.clinicalTrialFilters[organisationId] };
    if (noValue(newState)) {
      newState = {};
    } else {
      Object.keys(newState).forEach(key => {
        delete newState[key];
      });
    }
    return {
      ...state,
      clinicalTrialFilters: {
        ...state.clinicalTrialFilters,
        [organisationId]: newState,
      },
      blockedForClinicalTrialExport: {
        ...state.blockedForClinicalTrialExport,
        [organisationId]: true,
      },
      clinicalTrialChangedFilters: {
        ...state.clinicalTrialChangedFilters,
        [organisationId]: true,
      },
    };
  },
  [types.SET_VIEW]: (state, action) => {
    return {
      ...state,
      view: action.payload.view,
    };
  },

  [types.SET_VIEW_RESET_STATE]: (state, action) => ({
    ...state,
    view: action.payload.view,
    purchasingLoading: false,
  }),

  [types.SET_FILTER_GROUP]: (state, action) => {
    let newState = { ...state.filters };
    const { organisationId, id, value } = action.payload;
    if (noValue(value) && id !== 'term') {
      delete newState[organisationId][id];
    } else {
      newState = {
        ...newState,
        [organisationId]: {
          ...newState[organisationId],
          [id]: value,
        },
      };
    }
    if (Object.keys(newState[organisationId]).length === 0) {
      delete newState[organisationId];
    }

    return {
      ...state,
      filters: newState,
      changedFilters: { ...state.changedFilters, [organisationId]: true },
      blockedForResearchersExport: {
        ...state.blockedForResearcherExport,
        [organisationId]: true,
      },
    };
  },

  [types.SET_WORKITEM_CATEGORY_FILTER_GROUP]: (state, action) => {
    let newState = { ...state.filters };
    const { organisationId, id, value } = action.payload;
    if (newState[organisationId]?.workItemCategories === undefined) {
      newState[organisationId] = {
        ...newState[organisationId],
        workItemCategories: {
          funding: true,
          tradeshows: true,
          publications: true,
          profile: true,
          clinicalTrials: true,
        },
      };
    }

    if (noValue(value)) {
      delete newState[organisationId].workItemCategories[id];
    } else {
      newState = {
        ...newState,
        [organisationId]: {
          ...newState[organisationId],
          workItemCategories: {
            ...newState[organisationId].workItemCategories,
            [id]: value,
          },
        },
      };
    }

    return {
      ...state,
      filters: newState,
      changedFilters: { ...state.changedFilters, [organisationId]: true },
      blockedForResearchersExport: {
        ...state.blockedForResearcherExport,
        [organisationId]: true,
      },
    };
  },

  [types.SET_WORKITEM_CATEGORY_ITEM_SEARCH_FILTER_GROUP]: (state, action) => {
    let newState = { ...state.workItemFilters };
    const { organisationId, id, value } = action.payload;

    if (newState[organisationId]?.workItemCategories === undefined) {
      newState[organisationId] = {
        ...newState[organisationId],
        workItemCategories: {
          funding: true,
          tradeshows: true,
          publications: true,
          profile: true,
          clinicalTrials: true,
        },
      };
    }

    if (noValue(value)) {
      delete newState[organisationId].workItemCategories[id];
    } else {
      newState = {
        ...newState,
        [organisationId]: {
          ...newState[organisationId],
          workItemCategories: {
            ...newState[organisationId].workItemCategories,
            [id]: value,
          },
        },
      };
    }

    return {
      ...state,
      workItemFilters: newState,
      blockedForWorkItemExport: {
        ...state.blockedForWorkItemExport,
        [organisationId]: true,
      },
      workItemChangedFilters: {
        ...state.workItemChangedFilters,
        [organisationId]: true,
      },
    };
  },
  [types.SET_CLINICALTRIAL_FILTERS_BY_STATE_GROUP]: (state, action) => {
    const { organisationId, newState } = action.payload;
    return {
      ...state,
      clinicalTrialFilters: {
        ...state.clinicalTrialFilters,
        [organisationId]: newState,
      },
    };
  },
  [types.SET_ORG_RESEARCHER_FILTERS_BY_STATE_GROUP]: (state, action) => {
    const { organisationId, newState } = action.payload;
    return {
      ...state,
      filters: {
        ...state.filters,
        [organisationId]: newState,
      },
    };
  },
  [types.SET_WORKITEM_FILTERS_BY_STATE_GROUP]: (state, action) => {
    const { organisationId, newState } = action.payload;
    return {
      ...state,
      workItemFilters: {
        ...state.workItemFilters,
        [organisationId]: newState,
      },
    };
  },
  [types.SET_PURCHASING_SEARCH_REQUEST]: (state, action) => {
    if (action.payload.error) {
      handleSearchError(action.payload.error);
    }
    const { organisationId, searchRequest } = action.payload;
    if (searchRequest.res) {
      return {
        ...state,
        purchasingSearchRequest: {
          ...state.purchasingSearchRequest,
          [organisationId]: searchRequest.res,
        },
        purchasingLoading: false,
      };
    }
    if (searchRequest.loading) {
      return {
        ...state,
        purchasingLoading: searchRequest.loading,
      };
    }

    return {
      ...state,
      purchasingLoading: false,
    };
  },

  [types.PERFORM_PURCHASING_SEARCH]: (state, action) => {
    const { purchasingLastSuccessfulSearch } = state;
    const {
      useLastSuccessfulSearch,
      newSearch,
      organisationId,
    } = action.payload;
    const query = useLastSuccessfulSearch
      ? purchasingLastSuccessfulSearch.query
      : PurchasingBuildQuery(state.purchasingFilters);
    const cancelPurchasingSearch = action.payload.request({
      url: purchasingSearch,
      body: {
        id,
        query: query ?? '(*)',
        count: state.purchasingPageSize,
        from: newSearch
          ? 0
          : state.purchasingPageNumber * state.purchasingPageSize,
        order: state.purchasingSort,
        orderBy: state.purchasingSortDir,
        location: 'OrganisationPage',
        entityId: organisationId,
      },
    });

    return {
      ...state,
      purchasingLastSuccessfulSearch: { query, ...state.purchasingFilters },
      purchasingPageNumber: newSearch ? 0 : state.purchasingPageNumber,
      purchasingSearchRequest: {
        res: {},
        error: false,
      },
      purchasingLoading: true,
      cancelPurchasingSearch,
    };
  },
  [types.SET_PURCHASING_FILTER]: (state, action) => {
    const filters = { ...state.purchasingFilters };
    const { id, value } = action.payload;
    if (noValue(value)) {
      delete filters[id];
    } else {
      filters[id] = value;
    }
    return {
      ...state,
      purchasingFilters: filters,
    };
  },
  [types.SET_PURCHASING_FILTER_GROUP]: (state, action) => {
    let filters = { ...state.purchasingFilters };
    const { group, id, value } = action.payload;
    if (noValue(value)) {
      delete filters[group][id];
    } else {
      filters = {
        ...filters,
        [group]: {
          ...filters[group],
          [id]: value,
        },
      };
    }
    if (Object.keys(filters[group]).length === 0) {
      delete filters[group];
    }
    return {
      ...state,
      purchasingFilters: filters,
    };
  },
  [types.SET_PURCHASING_SORT]: (state, action) => {
    let sort;
    let sortDir;

    if (action.payload.sort === state.purchasingSort) {
      if (state.purchasingSortDir === 'asc') {
        sort = action.payload.sort;
        sortDir = 'desc';
      } else {
        sort = '';
        sortDir = '';
      }
    } else {
      sort = action.payload.sort;
      sortDir = 'asc';
    }

    return {
      ...state,
      purchasingSort: sort,
      purchasingSortDir: sortDir,
    };
  },
  [types.SET_PURCHASING_PAGE_SIZE]: (state, action) => ({
    ...state,
    purchasingPageSize: action.payload.size,
  }),
  [types.SET_PURCHASING_PAGE_NUMBER]: (state, action) => ({
    ...state,
    purchasingPageNumber: action.payload.number,
  }),
  [types.CLEAR_PURCHASING_FILTERS]: state => ({
    ...state,
    purchasingFilters: {
      ...defaultState.purchasingFilters,
    },
  }),
  [types.DISMISS_NOTICE]: state => {
    return {
      ...state,
      showNotice: false,
    };
  },
  [types.DISMISS_CLINICAL_TRIAL_NOTICE]: state => {
    return {
      ...state,
      showClinicalTrialNotice: false,
    };
  },
  [types.SET_WORKITEM_PAGE_NUMBER]: (state, action) => {
    const { organisationId, pageNumber } = action.payload;
    return {
      ...state,
      workItemPageNumbers: {
        ...state.workItemPageNumbers,
        [organisationId]: pageNumber,
      },
    };
  },
  [types.SET_WORKITEM_PAGE_SIZE]: (state, action) => {
    const { organisationId, pageSize } = action.payload;
    return {
      ...state,
      workItemPageSize: {
        ...state.workItemPageSize,
        [organisationId]: pageSize,
      },
    };
  },
  [types.SET_WORKITEM_SORT]: (state, action) => {
    const { organisationId, sort } = action.payload;
    return {
      ...state,
      workItemSort: { ...state.workItemSort, [organisationId]: sort },
    };
  },
  [types.SET_WORKITEM_SEARCH_REQUEST]: (state, action) => {
    const { organisationId, searchRequest } = action.payload;
    if (action.payload.searchRequest?.error) {
      handleSearchError(action.payload.searchRequest.error);
    }
    if (searchRequest.res) {
      return {
        ...state,
        workItemTotals: {
          ...state.workItemTotals,
          [organisationId]: {
            emailCount: searchRequest.res.emailCount,
            people: searchRequest.res.distinctSuperResearchersCount,
            countries: searchRequest.res.countryCount,
            activities: searchRequest.res.activityCount,
          },
        },
        blockedForWorkItemExport: {
          ...state.blockedForWorkItemExport,
          [organisationId]: false,
        },
        workItems: {
          ...state.workItems,
          [organisationId]: searchRequest.res,
        },
        workItemLoading: { ...state.workItemLoading, [organisationId]: false },
        workItemSearchError: {
          ...state.workItemSearchError,
          [organisationId]: action.payload.searchRequest?.error,
        },
      };
    }
    return {
      ...state,
      workItemLoading: {
        ...state.workItemLoading,
        [organisationId]: searchRequest.loading ?? false,
      },
      workItemSearchError: {
        ...state.workItemSearchError,
        [organisationId]: action.payload.searchRequest?.error,
      },
      workItems: {},
      workItemTotals: {},
      blockedForWorkItemExport: {
        ...state.blockedForWorkItemExport,
        [organisationId]: false,
      },
    };
  },
  [types.PERFORM_WORKITEM_SEARCH]: (state, action) => {
    const { organisationId, request } = action.payload;
    if (!getHasValidItemSearchQuery(state, organisationId)) {
      return {
        ...state,
        workItemChangedFilters: {
          ...state.workItemChangedFilters,
          [organisationId]: true,
        },
      };
    }

    const initialPageNumber = 0;

    const [query, highlightQuery, terms, nestedQueries] = buildQuery(
      state.workItemFilters[organisationId],
      null,
      null,
      true
    );

    const pageNumber = state.workItemChangedFilters[organisationId]
      ? initialPageNumber
      : state.workItemPageNumbers[organisationId] ?? initialPageNumber;

    const cancelWorkItemsSearch = request({
      url: searchSuperOrganisationWorkItems(organisationId),
      body: {
        id,
        query: `${query}`,
        count: state.workItemPageSize[organisationId] ?? 50,
        from: (state.workItemPageSize[organisationId] ?? 50) * pageNumber,
        orderBy: state.workItemSort[organisationId]?.field || 'Date',
        order: state.workItemSort[organisationId]?.direction || 'desc',
        filters: terms,
        nestedQueries: nestedQueries,
        highlightQuery,
      },
    });

    return {
      ...state,
      workItemPageNumbers: {
        ...state.workItemPageNumbers,
        [organisationId]: state.workItemPageNumbers[organisationId],
      },
      workItemChangedFilters: {
        ...state.workItemChangedFilters,
        [organisationId]: false,
      },
      cancelWorkItemsSearch,
    };
  },
  [types.SET_WORKITEM_FILTER_GROUP]: (state, action) => {
    let newState = { ...state.workItemFilters };
    const { organisationId, id, value } = action.payload;
    if (noValue(value) && id !== 'term') {
      delete newState[organisationId][id];
    } else {
      newState = {
        ...newState,
        [organisationId]: {
          ...newState[organisationId],
          [id]: value,
        },
      };
    }
    if (Object.keys(newState[organisationId]).length === 0) {
      delete newState[organisationId];
    }

    return {
      ...state,
      workItemFilters: newState,
      blockedForWorkItemExport: {
        ...state.blockedForWorkItemExport,
        [organisationId]: true,
      },
      workItemChangedFilters: {
        ...state.workItemChangedFilters,
        [organisationId]: true,
      },
    };
  },
  [types.RESET_WORKITEM_FILTERS]: (state, action) => {
    const { organisationId } = action.payload;
    let newState = { ...state.workItemFilters[organisationId] };
    if (noValue(newState)) {
      newState = {};
    } else {
      Object.keys(newState).forEach(key => {
        if (resetSkipKeys.findIndex(k => k === key) < 0) delete newState[key];
      });
    }

    return {
      ...state,
      workItemFilters: { ...state.workItemFilters, [organisationId]: newState },
      blockedForWorkItemExport: {
        ...state.blockedForWorkItemExport,
        [organisationId]: true,
      },
      workItemChangedFilters: {
        ...state.workItemChangedFilters,
        [organisationId]: true,
      },
    };
  },
  [types.CLEAR_WORKITEM_FILTERS]: (state, action) => {
    const { organisationId } = action.payload;
    let newState = { ...state.workItemFilters[organisationId] };
    if (noValue(newState)) {
      newState = {};
    } else {
      Object.keys(newState).forEach(key => {
        delete newState[key];
      });
    }
    return {
      ...state,
      workItemFilters: { ...state.workItemFilters, [organisationId]: newState },
      blockedForWorkItemExport: {
        ...state.blockedForWorkItemExport,
        [organisationId]: true,
      },
      workItemChangedFilters: {
        ...state.workItemChangedFilters,
        [organisationId]: true,
      },
    };
  },
  [types.RESET_ALL_FILTERS]: (state, action) => {
    const { organisationId, hasIndustryLicense } = action.payload;
    return {
      ...state,
      filters: {
        ...state.filters,
        [organisationId]: {
          workItemCategories: {
            funding: true,
            tradeshows: true,
            publications: true,
            profile: hasIndustryLicense,
            clinicalTrials: true,
          },
        },
      },
      workItemFilters: {
        ...state.workItemFilters,
        [organisationId]: {
          workItemCategories: {
            funding: true,
            tradeshows: true,
            publications: true,
            profile: hasIndustryLicense,
            clinicalTrials: true,
          },
          date:
            state.workItemFilters[organisationId] &&
            state.workItemFilters[organisationId]['fromRecentItems']
              ? 'one'
              : 'all',
        },
      },
      blockedForWorkItemExport: {
        ...state.blockedForWorkItemExport,
        [organisationId]: true,
      },
      blockedForClinicalTrialExport: {
        ...state.blockedForClinicalTrialExport,
        [organisationId]: true,
      },
      blockedForResearchersExport: {
        ...state.blockedForResearchersExport,
        [organisationId]: true,
      },
      purchasingLoading: false,
      purchasingFilters: {},
      purchasingSearchRequest: {
        ...state.purchasingSearchRequest,
        [organisationId]: null,
      },
    };
  },
  [types.CANCEL_SEARCH]: (state, action) => {
    state.cancelResearchersSearch?.();
    state.cancelWorkItemsSearch?.();
    state.cancelPurchasingSearch?.();
    state.cancelClinicalTrialsSearch?.();
    const { organisationId } = action.payload;

    if (state.view === ORGANISATIONPEOPLEVIEW) {
      return {
        ...state,
        organisationResearchers: {
          ...state.organisationResearchers,
          [organisationId]: {},
        },
        loading: { ...state.loading, [organisationId]: false },
        blockedForResearchersExport: {
          ...state.blockedForResearcherExport,
          [organisationId]: false,
        },
        totals: {
          ...state.totals,
          [organisationId]: {},
        },
        researcherSearchError: {
          ...state.researcherSearchError,
          [organisationId]: null,
        },
      };
    }

    if (state.view === ORGANISATIONWORKITEMVIEW) {
      return {
        ...state,
        workItemTotals: {
          ...state.workItemTotals,
          [organisationId]: {},
        },
        blockedForWorkItemExport: {
          ...state.blockedForWorkItemExport,
          [organisationId]: false,
        },
        workItems: {
          ...state.workItems,
          [organisationId]: {},
        },
        workItemLoading: { ...state.workItemLoading, [organisationId]: false },
        workItemSearchError: {
          ...state.workItemSearchError,
          [organisationId]: null,
        },
      };
    }

    if (state.view === ORGANISATIONPURCHASINGVIEW) {
      return {
        ...state,
        purchasingSearchRequest: {
          ...state.purchasingSearchRequest,
          [organisationId]: {},
        },
        purchasingLoading: false,
      };
    }

    if (state.view === ORGANISATIONCLINICALTRIALSVIEW) {
      return {
        ...state,
        clinicalTrialTotals: {
          ...state.clinicalTrialTotals,
          [organisationId]: {},
        },
        blockedForClinicalTrialExport: {
          ...state.blockedForClinicalTrialExport,
          [organisationId]: false,
        },
        organisationClinicalTrials: {
          ...state.organisationClinicalTrials,
          [organisationId]: {},
        },
        loadingClinicalTrialData: {
          ...state.loadingClinicalTrialData,
          [organisationId]: false,
        },
        clinicalTrialSearchError: {
          ...state.clinicalTrialSearchError,
          [organisationId]: null,
        },
        clinicalTrialChangedFilters: {
          ...state.clinicalTrialChangedFilters,
          [organisationId]: false,
        },
      };
    }

    return state;
  },
};

const reducer = (state = defaultState, action) => {
  const handler = REDUCER[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;

import { useEffect, useState, useRef, useCallback } from 'react';
import qs from 'query-string';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { Alert } from 'antd';

import { useAuth, usePost } from 'hooks';
import { failedLogin } from 'services/api';

import licenseType from 'models/licenseType';
import { ResearcherSearch } from 'components/Search/Results/helpers';
import { clearFilters, setView } from 'actions/search';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchView } from 'selectors/search';
import { view } from 'components/Search/Results/constants';
import { Button } from 'components/common';

import FromSalesforce from './FromSalesforce';
import { Container, InnerContent, ContentBody } from './styled';
import CredentialsForm from './CredentialsForm';
import CodeForm from './CodeForm';
import { tfaHasBeenEnabledDownloadFile } from './tfaConstants';
import {
  getPreConditionError,
  getErrorMessage,
  getErrorForLog,
} from './errors';

const REMEMBER_ME_USERNAME_KEY = 'REMEMBER_ME_USERNAME_KEY';

const Login = () => {
  const {
    loggingIn,
    loginError,
    logIn,
    externalLogIn,
    externalLoggingIn,
    externalLoginError,
    logOut,
    isAuthed,
    verifyCode,
    verifyCodeError,
    isVerifying,
    user,
    hasLicense,
    softLogOut,
  } = useAuth();
  const [error, setError] = useState();
  const emailRef = useRef();
  const [, postFailedLogin] = usePost({ url: failedLogin });
  const rememberMeUsername = localStorage.getItem(REMEMBER_ME_USERNAME_KEY);
  const [form, setForm] = useState({
    username: rememberMeUsername || '',
    password: '',
    rememberMe: !!rememberMeUsername,
  });
  const [code, setCode] = useState('');
  const { search } = useLocation();
  const { push } = useHistory();
  const { redirect, ...queryParams } = qs.parse(search);
  const searchView = useSelector(getSearchView);
  const dispatch = useDispatch();

  emailRef.current = form.username;

  useEffect(() => {
    logOut();
  }, [logOut]);

  useEffect(() => {
    if (loginError) {
      let error;
      // 412 code is used for different errors
      if (loginError.status === 412) {
        error = getPreConditionError(loginError);
      } else {
        error = getErrorForLog(loginError);
      }

      postFailedLogin({ email: emailRef.current, error });
      setError(getErrorMessage(loginError));
    }
  }, [postFailedLogin, loginError]);

  useEffect(() => {
    if (externalLoginError) {
      setError(getErrorMessage(externalLoginError));
    }
  }, [externalLoginError]);

  useEffect(() => {
    setError(verifyCodeError?.message);
    if (verifyCodeError?.status === 412) {
      setForm({
        username: '',
        password: '',
        rememberMe: false,
      });
    }
    setCode('');
    const { fcWidget } = window;
    if (verifyCodeError && !fcWidget?.isOpen()) {
      fcWidget?.open();
    }
  }, [verifyCodeError]);

  const handleChange = key => value => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const noDetails = !form.username || !form.password;

  const handleLogin = () => {
    if (noDetails) return;
    setError(null);
    if (form.rememberMe) {
      localStorage.setItem(REMEMBER_ME_USERNAME_KEY, form.username);
    } else {
      localStorage.removeItem(REMEMBER_ME_USERNAME_KEY);
    }
    logIn(form);
  };

  const handleExternalLogin = (provider, credential) => {
    if (!credential || !provider) return;
    setError(null);
    externalLogIn({
      provider: provider,
      credential: credential,
    });
  };

  const handleReset = () => {
    push('/reset');
  };

  const handleVerifyCode = () => {
    if (!code) return;
    verifyCode({ code, ...form });
  };

  const toLogin = useCallback(() => {
    setError('');
    setCode('');
    softLogOut();
  }, [softLogOut]);

  if (isAuthed && (!user.tfaEnabled || user.totpVerified)) {
    let redirectString = `${redirect || ''}?${qs.stringify(queryParams, {
      encode: false,
    })}`;

    const hasOrgLicense = hasLicense(
      [licenseType.industry, licenseType.academic],
      true
    );
    if (!hasOrgLicense) {
      if (!ResearcherSearch(searchView)) {
        dispatch(setView(view.researcher));
        dispatch(clearFilters);
      }
      if (redirectString.startsWith('/organisation/')) {
        redirectString = '';
      }
    }

    return <Redirect to={redirectString} />;
  }

  return (
    <>
      <div className='form-header'>
        {!isAuthed ? 'Login' : 'Two-Factor Authentication'}
      </div>
      <Container>
        <ContentBody>
          <InnerContent>
            {!isAuthed ? (
              <CredentialsForm
                form={form}
                isLoading={loggingIn || externalLoggingIn || externalLoggingIn}
                handleLogin={handleLogin}
                handleChange={handleChange}
                handleReset={handleReset}
                handleExternalLogin={handleExternalLogin}
              />
            ) : (
              <CodeForm
                code={code}
                setCode={setCode}
                isLoading={isVerifying}
                handleVerifyCode={handleVerifyCode}
              />
            )}
            {!isAuthed && queryParams.download && (
              <Alert
                message={
                  queryParams.status === 'tfaenabled'
                    ? tfaHasBeenEnabledDownloadFile
                    : 'Please log in to access your download'
                }
                type='info'
              />
            )}
            {!isAuthed && queryParams.status === 'userdisabled' && (
              <Alert
                message='Your user has been disabled. Please contact support to speak about your account.'
                type='info'
              />
            )}
            {!isAuthed && queryParams.status === 'accountinactive' && (
              <Alert
                message='Your account is inactive. Please contact support to speak about your account.'
                type='info'
              />
            )}
            {!isAuthed && queryParams.status === 'expiredlicence' && (
              <Alert
                message='Your licence has expired. Please contact support to speak about your account.'
                type='info'
              />
            )}
            {!isAuthed && queryParams.status === 'expiredlicence' && (
              <Alert
                message='Your licence has expired. Please contact support to speak about your account.'
                type='info'
              />
            )}
            {error && <Alert message={error} type='error' />}
          </InnerContent>
          <FromSalesforce />
        </ContentBody>
      </Container>
      {isAuthed && (
        <Button
          type='link'
          disabled={isVerifying}
          onClick={toLogin}
          className='back-to-login'
        >
          Back to Login
        </Button>
      )}
    </>
  );
};

export default Login;

import { Fragment, useState, useEffect, useMemo } from 'react';
import { Modal } from 'antd';

import {
  Loader,
  TabbedPanel,
  Select,
  PublicationsFooter,
  FundingFooter,
} from 'components/common';
import { usePatch, useAuth } from 'hooks';
import { manageMembers } from 'services/api';

import {
  NotificationFailure,
  NotificationSuccess,
} from 'components/Lists/Notifications';
import { LABEL_BUTTON_ADD_NEW_LIST } from 'components/common/labelConstants';
import licenseType from 'models/licenseType';
import { ConstrainedTable } from './styled';
import Export from 'components/Export';
import { downloadType } from 'components/Export/Summary/constants';
import { getConfigs, perPage } from './config';

const Work = ({
  loading,
  researcher,
  editableLists,
  loadingLists,
  onExportNetwork,
  exportingNetwork,
  onCreateNewItemList,
  view,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [listToAdd, setListToAdd] = useState({});
  const { hasLicense, user } = useAuth();
  const hasOrgLicense = useMemo(
    () => hasLicense([licenseType.industry, licenseType.academic], true),
    [hasLicense]
  );
  const configs = useMemo(() => getConfigs(hasOrgLicense), [hasOrgLicense]);

  const [
    {
      res: addRes,
      loading: addingResearchers,
      error: addingMembersToListError,
    },
    patchMembers,
  ] = usePatch({});

  useEffect(() => {
    setShowModal(false);
    if (addRes) {
      NotificationSuccess();
    }

    if (addingMembersToListError && addingMembersToListError.status === 400) {
      NotificationFailure(addingMembersToListError.message);
    }
  }, [addRes, addingMembersToListError]);

  if (loading) return <Loader />;

  const {
    projects,
    tradeShows,
    publications,
    clinicalTrials,
    collaborationTable,
    researcherProfile,
    overViewStats = {},
  } = researcher;

  const {
    overview: {
      totalFirstAuthorCount,
      totalLastAuthorCount,
      totalCollaborators,
    },
  } = overViewStats;
  const { allTimeHIndexAverage, threeYearHIndexAverage } = researcherProfile;

  const handleAddAll = id => {
    if (id === 'no_lists') return;
    setShowModal(true);
    setListToAdd(editableLists.find(l => l.value === id));
  };

  const editableListOptions =
    editableLists && editableLists.length === 0
      ? [{ label: 'No lists available', value: 'no_lists' }]
      : editableLists;

  const handleOk = () => {
    patchMembers({
      url: manageMembers(listToAdd.value),
      body: {
        researcherIds: collaborationTable
          .filter(x => x.researcherId)
          .map(x => x.researcherId),
      },
    });
  };

  const config = {};

  if (projects.length) {
    config.funding = {
      icon: 'award',
      text: 'Funding',
      content: (
        <ConstrainedTable
          key='funding-table'
          config={configs.funding}
          data={projects}
          pageSizeOptions={perPage}
          footerInfo={
            <FundingFooter
              allTime={researcherProfile.allTimeTotalFunding}
              threeYear={researcherProfile.threeYearTotalFunding}
            />
          }
          paginate
        />
      ),
    };
  }
  if (tradeShows.length) {
    config.posters = {
      icon: 'image',
      text: 'Posters',
      content: (
        <ConstrainedTable
          key='poster-table'
          config={configs.posters}
          data={tradeShows}
          pageSizeOptions={perPage}
          paginate
        />
      ),
    };
  }
  if (publications.length) {
    config.publications = {
      icon: 'book',
      text: 'Publications',
      content: (
        <ConstrainedTable
          key='publication-table'
          config={configs.publications}
          data={publications}
          pageSizeOptions={perPage}
          footerInfo={
            <PublicationsFooter
              firstNamedCount={totalFirstAuthorCount}
              lastNamedCount={totalLastAuthorCount}
              totalCollabs={totalCollaborators}
              impactAll={allTimeHIndexAverage}
              impactThree={threeYearHIndexAverage}
            />
          }
          paginate
        />
      ),
    };
  }
  if (clinicalTrials.length) {
    config.clinicalTrials = {
      icon: 'trial',
      text: 'Trials',
      content: (
        <ConstrainedTable
          key='trial-table'
          config={configs.trials}
          data={clinicalTrials}
          pageSizeOptions={perPage}
          paginate
        />
      ),
    };
  }
  if (collaborationTable.length) {
    config.network = {
      icon: 'network',
      text: 'Network',
      content: (
        <ConstrainedTable
          key='network-table'
          config={configs.network}
          data={collaborationTable}
          pageSizeOptions={perPage}
          footerActions={
            <div>
              <Select
                value='Add All to List'
                placeholder='Add All to List'
                onCreateNewItem={() => onCreateNewItemList(collaborationTable)}
                buttonText={LABEL_BUTTON_ADD_NEW_LIST}
                size='small'
                disabled={!editableLists || loadingLists}
                options={editableListOptions}
                onChange={handleAddAll}
                dropdownStyle={{ minWidth: '225px' }}
                style={{ marginLeft: 10, width: 125 }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
                disableTooltip
              />
              {!user.isRateLimited && !user.disableExport && (
                <Export
                  downloadType={downloadType.network}
                  getQueryBody={() => ({
                    researcherId: researcher?.researcherProfile?.id,
                  })}
                  disabled={exportingNetwork}
                  onExport={onExportNetwork}
                  style={{ marginLeft: 10 }}
                />
              )}
            </div>
          }
          paginate
          sortable
        />
      ),
    };
  }

  if (config && Object.keys(config).length === 0)
    return <p>No Work Items available</p>;

  return (
    <>
      <TabbedPanel horizontal config={config} view={view} />
      <Modal
        open={showModal}
        title={`Add Researcher Network To ${listToAdd.label}`}
        onCancel={() => setShowModal(false)}
        onOk={handleOk}
        okButtonProps={{ disabled: addingResearchers }}
        cancelButtonProps={{ disabled: addingResearchers }}
      >
        <p>
          Would you like to add all researchers in this network to{' '}
          {listToAdd.label}?
          <br />
          <br />
          Please note, only researchers with a SciLeads profile will be added.
        </p>
      </Modal>
    </>
  );
};

export default Work;

export const messageTypes = {
  generic: 1,
  tradeshow: 2,
  pastTradeshow: 3,
  meeting: 4,
};

export const messageTypesConfig = [
  {
    id: messageTypes.generic,
    title: 'Generic outreach',
    icon: 'message',
  },
  {
    id: messageTypes.tradeshow,
    title: 'Meet at upcoming tradeshow',
    icon: 'store',
  },
  {
    id: messageTypes.pastTradeshow,
    title: 'Past tradeshow',
    icon: 'history',
  },
  {
    id: messageTypes.meeting,
    title: "Meet in the contact's city",
    icon: 'locationDot',
  },
];

export const keywordTypes = {
  default: 0,
  custom: 1,
  custonAndDefault: 2,
};

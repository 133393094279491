import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Modal, Tooltip, notification } from 'antd';

import { usePost, useGet, usePatch, useDel } from 'hooks';

import {
  getResearcher,
  getListsForUser,
  exportNetwork,
  manageMembers,
  deleteList,
} from 'services/api';

import { Button, Icon } from 'components/common';
import { colours } from 'utils/theme';

import Profile from './Profile';
import Statistics from './Statistics';
import Graphs from './Graphs';
import Work from './Work';
import Lists from './Lists';
import NetworkDiagram from './NetworkDiagram';
import {
  Container,
  Column,
  LeftColumn,
  GraphColumn,
  Pane,
  Row,
  OrangeDot,
  TealDot,
  KeyContainer,
} from './styled';

import { useSelector, useDispatch } from 'react-redux';
import { Create } from 'components/common/Lists/Modals';
import { getActiveModal } from 'selectors/lists';
import { setModal } from 'actions/lists';
import {
  NotificationFailure,
  NotificationSuccess,
  NotificationSuccessAssignOneResearcherToList,
} from 'components/Lists/Notifications';
import { TITLE_POPUP_CREATE_LIST } from 'components/common/labelConstants';
import TopCategories from './Profile/TopCategories';
import NotFound from './NotFound';
import InactiveNotice from './Profile/InactiveNotice';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { handleFailedExportNotification } from 'components/Search/Results/Actions/notifications';
import CTA from './CTA';

const modals = {
  create: Create,
};

const HelpText = () => (
  <div>
    <KeyContainer>
      <OrangeDot />
      Researcher with SciLeads profile (click to open)
    </KeyContainer>
    <KeyContainer>
      <TealDot />
      Researcher without SciLeads profile
    </KeyContainer>
    <div style={{ marginTop: 10 }}>Scroll to zoom, click & hold to pan</div>
  </div>
);

export const NetworkActions = ({
  largeCollaborationNetwork,
  loading,
  toggleModal,
}) => {
  if (largeCollaborationNetwork) return null;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        size='small'
        disabled={loading}
        onClick={toggleModal}
        style={{ marginRight: 10 }}
      >
        Expand
      </Button>
      <Tooltip title={<HelpText />} placement='left'>
        <div style={{ marginTop: '-2px', cursor: 'pointer' }}>
          <Icon icon='help' color={colours.teal} size='xs' />
        </div>
      </Tooltip>
    </div>
  );
};

const MeshHelpText = () => (
  <div>
    <p>
      These keywords cover the specific concepts within each individual
      scientific papers that a researcher publishes. The visualisation shows a
      combination from across all a researcher's papers which helps show key
      scientific concepts particular to this researcher.
    </p>
  </div>
);

export const MeshActions = ({ loading, toggleModal }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        size='small'
        disabled={loading}
        onClick={toggleModal}
        style={{ marginRight: 10 }}
      >
        Expand
      </Button>
      <Tooltip title={MeshHelpText} placement='left'>
        <div style={{ marginTop: '-2px', cursor: 'pointer' }}>
          <Icon icon='help' color={colours.teal} size='xs' />
        </div>
      </Tooltip>
    </div>
  );
};

const WorkItemsHelpText = () => (
  <div>
    <p>
      Interactive graph showing the activities by year over a researcher's
      career. Select a specific activity to remove it from the graph.
    </p>
  </div>
);

export const WorkItemsActions = ({ loading, toggleModal }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        size='small'
        disabled={loading}
        onClick={toggleModal}
        style={{ marginRight: 10 }}
      >
        Expand
      </Button>
      <Tooltip title={WorkItemsHelpText} placement='left'>
        <div style={{ marginTop: '-2px', cursor: 'pointer' }}>
          <Icon icon='help' color={colours.teal} size='xs' />
        </div>
      </Tooltip>
    </div>
  );
};

const OPTION_NONE = 0;
const OPTION_LIST_MANAGEMENT = 1;
const OPTION_WORK = 2;

const OPERATION_STATUS_BEGIN = 1;
const OPERATION_STATUS_NOT_DEFINED = 3;
const OPERATION_STATUS_ASSIGN_RESEARCHERS_TO_LIST_BEGIN = 4;
const OPERATION_STATUS_ASSIGN_RESEARCHERS_TO_LIST_SUCCESS = 6;
const OPERATION_STATUS_ASSIGN_RESEARCHERS_TO_LIST_FAIL = 7;

const Researcher = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { overrideView } = location.state || {};
  const activeModal = useSelector(getActiveModal);
  const [showNetworkModal, setShowNetworkModal] = useState(false);
  const [showMeshModal, setShowMeshModal] = useState(false);
  const [showWorkItemsModal, setShowWorkItemsModal] = useState(false);
  const { id } = useParams();

  const [
    { res: researcher, error: researcherError, loading },
    fetchResearcher,
  ] = usePost({
    url: getResearcher,
    body: { id },
  });

  const [
    refreshListAssignedToResearcher,
    setRefreshListAssignedToResearcher,
  ] = useState(null);

  const [researcherFormFlags, setResearcherFormFlags] = useState({
    ListId: null,
    OptionExecute: OPTION_NONE,
    OperationStatus: OPERATION_STATUS_NOT_DEFINED,
    Notification: () => {},
  });

  const [collaborationTable, setCollaborationTable] = useState([]);

  const [
    { res: editableListsResponse, loading: loadingLists },
    fetchList,
  ] = useGet({
    url: getListsForUser,
    onMount: true,
    query: { onlyEditable: true },
  });

  const [
    {
      res: exportingNetworkRes,
      error: exportNetworkError,
      loading: exportingNetwork,
    },
    postExport,
  ] = usePost({
    url: exportNetwork,
  });

  const [
    { res: createAndAddListResponse, error: addingMembersToListError },
    patchMembers,
  ] = usePatch({
    url: '',
  });

  const [{ res }, delList] = useDel({
    url: '',
  });

  useEffect(() => {
    fetchResearcher();
  }, [id, fetchResearcher]);

  useEffect(() => {
    if (researcher && !researcher.redirectId && !researcher.showCta) {
      document.title = `${researcher.researcherProfile.researcherFullName} | SciLeads`;
    }
  }, [researcher]);

  useEffect(() => {
    if (createAndAddListResponse) {
      setResearcherFormFlags(prev => {
        return {
          ...prev,
          OperationStatus: OPERATION_STATUS_ASSIGN_RESEARCHERS_TO_LIST_SUCCESS,
          NextOperation: () => {},
        };
      });
    }

    if (addingMembersToListError && addingMembersToListError.status === 400) {
      setResearcherFormFlags(prev => {
        return {
          ...prev,
          OperationStatus: OPERATION_STATUS_ASSIGN_RESEARCHERS_TO_LIST_FAIL,
          NextOperation: () => {},
          Notification: NotificationFailure(
            addingMembersToListError.message,
            true
          ),
        };
      });
    }
  }, [createAndAddListResponse, addingMembersToListError]);

  useEffect(() => {
    if (res) {
      setResearcherFormFlags({
        ListId: null,
        OptionExecute: OPTION_NONE,
        OperationStatus: OPERATION_STATUS_NOT_DEFINED,
        Notification: () => {},
      });
    }
  }, [res]);

  useEffect(() => {
    if (
      (researcherFormFlags.OptionExecute === OPTION_LIST_MANAGEMENT ||
        researcherFormFlags.OptionExecute === OPTION_WORK) &&
      researcherFormFlags.OperationStatus === OPERATION_STATUS_BEGIN
    ) {
      researcherFormFlags.NextOperation();
    }

    if (
      (researcherFormFlags.OptionExecute === OPTION_LIST_MANAGEMENT ||
        researcherFormFlags.OptionExecute === OPTION_WORK) &&
      researcherFormFlags.OperationStatus ===
        OPERATION_STATUS_ASSIGN_RESEARCHERS_TO_LIST_BEGIN
    ) {
      researcherFormFlags.NextOperation();
    }

    if (
      (researcherFormFlags.OptionExecute === OPTION_LIST_MANAGEMENT ||
        researcherFormFlags.OptionExecute === OPTION_WORK) &&
      researcherFormFlags.OperationStatus ===
        OPERATION_STATUS_ASSIGN_RESEARCHERS_TO_LIST_SUCCESS
    ) {
      setRefreshListAssignedToResearcher(researcherFormFlags.ListId);
      fetchList();
      researcherFormFlags.Notification();
      // postResearchersLists({ researcherIds: [researcherId] });
      setResearcherFormFlags({
        ListId: null,
        OptionExecute: OPTION_NONE,
        OperationStatus: OPERATION_STATUS_NOT_DEFINED,
        Notification: () => {},
      });
    }

    if (
      (researcherFormFlags.OptionExecute === OPTION_LIST_MANAGEMENT ||
        researcherFormFlags.OptionExecute === OPTION_WORK) &&
      researcherFormFlags.OperationStatus ===
        OPERATION_STATUS_ASSIGN_RESEARCHERS_TO_LIST_FAIL
    ) {
      researcherFormFlags.Notification();
      delList({
        url: deleteList(researcherFormFlags.ListId),
      });
    }
  }, [researcherFormFlags, fetchList, delList /*, postResearchersLists */]);

  useEffect(() => {
    if (exportingNetworkRes) {
      notification.open({
        message: 'Generating Report',
        description:
          'We are generating a report for this network and will notify you when it is ready.',
        placement: 'bottomLeft',
      });
    }
  }, [exportingNetworkRes]);

  useEffect(() => {
    if (exportNetworkError) {
      handleFailedExportNotification(exportNetworkError.status);
    }
  }, [exportNetworkError]);

  const toggleNetworkModal = () => setShowNetworkModal(s => !s);
  const toggleMeshModal = () => setShowMeshModal(s => !s);
  const toggleWorkItemsModal = () => setShowWorkItemsModal(s => !s);

  if (researcher?.redirectId) {
    return <Redirect to={`/researcher/${researcher.redirectId}`} />;
  }

  if (researcherError) {
    return <NotFound />;
  }

  if (researcher?.showCta) {
    return <CTA />;
  }

  const networkModalTitle = researcher
    ? `Network Diagram for ${researcher.researcherProfile.researcherFullName}`
    : '';

  const meshModalTitle = researcher
    ? `Focused researcher Areas ${researcher.researcherProfile.researcherFullName}`
    : '';

  const workItemsModalTitle = researcher
    ? `Career Activities for ${researcher.researcherProfile.researcherFullName}`
    : '';

  const showLoader = loading || !researcher;

  const listOptions = editableListsResponse?.map(l => ({
    label: l.name,
    value: l.id,
    description: l.description,
  }));
  const categories = researcher?.researcherProfile
    ? researcher?.topCategories
    : {};

  const { overViewStats: researcherSummaryData } = researcher || {};

  const handleExportNetwork = params => {
    postExport({
      researcherId: researcher?.researcherProfile.id,
      ...params,
    });
  };

  const handleCreateNew = () => {
    setResearcherFormFlags({
      OptionExecute: OPTION_LIST_MANAGEMENT,
      OperationStatus: OPERATION_STATUS_BEGIN,
      ResearcherId: id,
      Notification: NotificationSuccessAssignOneResearcherToList,
      NextOperation: () => {
        setTimeout(() => dispatch(setModal('create')), 400);
      },
    });
  };

  const handleCreateNewWork = colTable => {
    setResearcherFormFlags({
      OptionExecute: OPTION_WORK,
      OperationStatus: OPERATION_STATUS_BEGIN,
      Notification: NotificationSuccess,
      NextOperation: () => {
        setCollaborationTable(colTable);
        setTimeout(() => dispatch(setModal('create')), 400);
      },
    });
  };

  const handleCloseModal = (options = { fetch: false, id: null }) => {
    dispatch(setModal(''));
    if (options.id && options.id !== null) {
      setResearcherFormFlags(prev => {
        if (prev.OptionExecute === OPTION_LIST_MANAGEMENT) {
          return {
            ...prev,
            ListId: options.id,
            OperationStatus: OPERATION_STATUS_ASSIGN_RESEARCHERS_TO_LIST_BEGIN,
            NextOperation: () => {
              patchMembers({
                url: manageMembers(options.id),
                body: { researcherIds: [prev.ResearcherId] },
              });
            },
          };
        } else {
          return {
            ...prev,
            ListId: options.id,
            OperationStatus: OPERATION_STATUS_ASSIGN_RESEARCHERS_TO_LIST_BEGIN,
            NextOperation: () => {
              patchMembers({
                url: manageMembers(options.id),
                body: {
                  researcherIds: collaborationTable
                    .filter(x => x.researcherId)
                    .map(x => x.researcherId),
                },
              });
            },
          };
        }
      });
    } else {
      setResearcherFormFlags({
        ListId: null,
        OptionExecute: OPTION_NONE,
        OperationStatus: OPERATION_STATUS_NOT_DEFINED,
        Notification: () => {},
      });
    }
  };

  const ModalCreateNewList = modals[activeModal];

  return (
    <Container>
      <Row>
        <LeftColumn>
          <Row alignTop>
            <Pane>
              <Profile
                loading={showLoader}
                researcher={researcher}
                fetchResearcher={fetchResearcher}
              />
            </Pane>
          </Row>

          <Row alignTop>
            <TopCategories
              researcher={researcher}
              loading={loading}
              categories={categories}
            />
          </Row>
          <Row alignTop>
            <Pane title='List Management'>
              <Lists
                editableLists={listOptions}
                loadingLists={loadingLists}
                onCreateNewItemList={handleCreateNew}
                newListCreatedId={refreshListAssignedToResearcher}
                researcherName={
                  researcher && researcher.researcherProfile.researcherFullName
                }
              />
            </Pane>
          </Row>
        </LeftColumn>
        <Column>
          <Statistics loading={showLoader} researcher={researcher} />
          <InactiveNotice researcherProfile={researcher?.researcherProfile} />
          <Pane style={{ flex: 0 }}>
            <Work
              loading={showLoader}
              view={overrideView}
              researcher={researcher}
              editableLists={listOptions}
              loadingLists={loadingLists}
              onExportNetwork={handleExportNetwork}
              exportingNetwork={exportingNetwork}
              onCreateNewItemList={handleCreateNewWork}
            />
          </Pane>
          <Row alignTop>
            <GraphColumn>
              <Pane
                title='Focused Research Areas'
                headerRight={
                  <MeshActions
                    loading={showLoader}
                    toggleModal={toggleMeshModal}
                  />
                }
              >
                <Graphs
                  loading={showLoader}
                  summaryData={researcher?.topMeshHeadings}
                  showLegend={false}
                  type='horizontalBar'
                  height='400px'
                  count='20'
                  labelLength='20'
                />
              </Pane>
              <Pane
                title='Career Activities'
                headerRight={
                  <WorkItemsActions
                    loading={showLoader}
                    toggleModal={toggleWorkItemsModal}
                  />
                }
              >
                <Graphs
                  loading={showLoader}
                  summaryData={researcherSummaryData}
                  showLegend={true}
                  type='bar'
                  labelLength='10'
                />
              </Pane>
            </GraphColumn>
            <GraphColumn>
              <Pane
                title='Network Diagram'
                headerRight={
                  <NetworkActions
                    loading={showLoader}
                    toggleModal={toggleNetworkModal}
                    largeCollaborationNetwork={
                      researcher?.largeCollaborationNetwork
                    }
                  />
                }
              >
                <NetworkDiagram
                  loading={showLoader}
                  network={researcher?.collaborationNetwork}
                  onExportNetwork={handleExportNetwork}
                  exportingNetwork={exportingNetwork}
                />
              </Pane>
            </GraphColumn>
          </Row>
        </Column>
      </Row>
      <Row alignTop>
        {/* <Column width='35%'>
          <Pane title='Network'>
            <Table
              loading={loading}
              style={{ marginTop: 10 }}
              config={configs.network}
              data={researcher?.collaborationNetwork?.nodes}
              manuallyPaginate
              pageSizeOptions={perPage}
            />
          </Pane>
        </Column> */}
      </Row>
      <Modal
        open={showNetworkModal}
        title={networkModalTitle}
        width='75vw'
        style={{ top: 30 }}
        okText='Close'
        onOk={toggleNetworkModal}
        onCancel={toggleNetworkModal}
        cancelText={null}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <NetworkDiagram
          fullscreen
          visible={showNetworkModal}
          loading={showLoader}
          network={researcher?.collaborationNetwork}
        />
      </Modal>
      <Modal
        open={showMeshModal}
        title={meshModalTitle}
        width='75vw'
        style={{ top: 30 }}
        okText='Close'
        onOk={toggleMeshModal}
        onCancel={toggleMeshModal}
        cancelText={null}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <Graphs
          loading={showLoader}
          summaryData={researcher?.topMeshHeadings}
          showLegend={false}
          type='horizontalBar'
          labelLength='35'
        />
      </Modal>
      <Modal
        open={showWorkItemsModal}
        title={workItemsModalTitle}
        width='75vw'
        style={{ top: 30 }}
        okText='Close'
        onOk={toggleWorkItemsModal}
        onCancel={toggleWorkItemsModal}
        cancelText={null}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <Graphs
          loading={showLoader}
          summaryData={researcherSummaryData}
          showLegend={true}
          type='bar'
          labelLength='10'
        />
      </Modal>
      {activeModal && (
        <ModalCreateNewList
          title={TITLE_POPUP_CREATE_LIST}
          buttonOkText='Create and Add'
          onClose={handleCloseModal}
        />
      )}
    </Container>
  );
};

export default Researcher;

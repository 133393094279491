import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Detail, Input, Select } from 'components/common';
import DetailsTooltip from './DetailsTooltip';

const Container = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  > div {
    flex: 1;
  }
`;

function TradeshowLocation({
  tradeshows,
  onTradeshowChange,
  tradeshow,
  location,
  onLocationChange,
  loading,
  style,
  showLocationInput,
}) {
  const options = useMemo(
    () =>
      tradeshows.map(t => ({
        label: t.name,
        value: t.id,
      })),
    [tradeshows]
  );

  return (
    <Container style={style}>
      <Detail label='Tradeshow' style={{ marginBottom: '16px' }}>
        <Select
          value={tradeshow}
          size='middle'
          options={options}
          onChange={onTradeshowChange}
          style={{ width: '100%' }}
          placement='bottomRight'
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
          showSearch
          disabled={loading}
        />
      </Detail>
      {showLocationInput && (
        <Detail
          label={
            <>
              Exact location to meet (e.g. Booth)
              <DetailsTooltip title='Maximum 50 characters' />
            </>
          }
        >
          <Input
            placeholder='Exact location to meet'
            value={location}
            onChange={onLocationChange}
            disabled={loading}
            style={{ width: '100%' }}
            maxLength={50}
          />
        </Detail>
      )}
    </Container>
  );
}

TradeshowLocation.propTypes = {
  tradeshows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  tradeshow: PropTypes.number,
  onTradeshowChange: PropTypes.func.isRequired,
  location: PropTypes.string,
  onLocationChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  style: PropTypes.shape({}),
  showLocationInput: PropTypes.bool,
};

export default TradeshowLocation;
